<template>
	<v-combobox v-bind="$attrs" :filter="filter" :items="users" item-text="username" item-value="id" :label="$t(label)" :loading="loading" v-on="$listeners">
		<template v-slot:item="data">
			<w-list-tile-content :key="data.item.id">
				<w-list-tile-title>{{ data.item.username }}</w-list-tile-title>
				<w-list-tile-sub-title class="caption">{{ data.item.email }}</w-list-tile-sub-title>
			</w-list-tile-content>
		</template>
	</v-combobox>
</template>

<script>
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'

export default {
	name: 'UserSelector',
	mixins: [CustomersManagerModuleGuard],
	props: {
		label: {
			default: 'customers.user',
			required: false,
			type: String
		}
	},
	data: function () {
		return {
			loading: false,
			users: []
		}
	},
	watch: {
		vendorId: {
			handler: function (newVal) {
				if (!newVal) {
					return
				}

				this.listUsers()
			},
			immediate: true
		}
	},
	methods: {
		filter: function (item, search) {
			const searchedTextRegEx = new RegExp(search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi')

			return item.username.match(searchedTextRegEx) || item.email.match(searchedTextRegEx)
		},
		listUsers: function () {
			this.loading = true

			return this.service
				.listClientUsers(this.accountingFirmId, this.vendorId)
				.promise.then(users => {
					this.users = users
				})
				.finally(() => {
					this.loading = false
				})
		}
	}
}
</script>
